<template>
  <section class="fantasy-name">
    <div
      class="mt-3 mb-5 flex flex-row-reverse"
      v-if="
        perfilHelpers.checkPermission([
          perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
        ])
      "
    >
      <vs-button color="primary" type="border" @click="includeNewFantasyName()">
        <i class="onpoint-plus icon-font pr-3"></i>
      Incluir  {{segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'}}</vs-button
      >
    </div>

    <div v-if="(seguradoFansatyName.list || []).length > 0">
      <vs-card v-for="(item, index) in seguradoFansatyName.list" :key="item.Id">
        <div class="flex items-center p-5">
          <div class="ml-2 mr-3 flex flex-col flex-grow">
            <p class="text-base font-semibold">
              {{ item.Name }}
            </p>
          </div>
          <vs-button
            radius
            color="primary"
            type="flat"
            @click="EditFantasyName(item, index)"
            size="large"
            icon="edit"
            v-if="
              perfilHelpers.checkPermission([
                perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
              ])
            "
          ></vs-button>
          <vs-button
            v-if="
              perfilHelpers.checkPermission([
                perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
              ]) && item.IsMainName != true
            "
            radius
            color="primary"
            type="flat"
            @click="removeFantasyName(item, index)"
            size="large"
            icon="delete"
          ></vs-button>
        </div>
      </vs-card>
    </div>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p class="text-center">
            Tomador ainda não possui nenhum nome fantasia cadastrado.
          </p>
        </div>
      </template>

    <vs-popup class="nomefantasia" title="" :active.sync="popupNomeFantasia">
      <h3 class="pl-4 pb-6">
        {{
          fantasyNameSelecionado.Id
            ? segurado.Entity === 'J' ? 'Editar  Nome Fantasia' : ' Editar Nome Social'
            : segurado.Entity === 'J' ? 'Adicionar Nome Fantasia' : 'Adicionar Nome Social'
        }}
      </h3>
      <div class="p-4 pt-0">
        <div class="vx-col" v-if="fantasyNameSelecionado.Id">
          <ValidationProvider
            :name="segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'"
            rules="required"
            v-slot="{ errors }"
          >
            <vs-input
              :label="segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'"
              size="large"
              class="w-full"
              v-model="fantasyNameSelecionado.Name"
            />
            <span class="text-danger " v-show="errors[0]">
              {{ errors[0] }}
            </span>
            <div class="p-4 mt-5 flex flex-row-reverse w-full">
              <vs-button @click="saveEditFantasyName()" :disabled="errors[0]"
                >Salvar</vs-button
              >
            </div>
          </ValidationProvider>
        </div>
        <div class="vx-col" v-else>
          <ValidationProvider
            :name="segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'"
            rules="required"
            v-slot="{ errors }"
          >
            <vs-input
              :label="segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'"
              size="large"
              class="w-full"
              v-model="novoNomeFantasia"
            />
            <span class="text-danger " v-show="errors[0]">
              {{ errors[0] }}
            </span>
            <div class="p-4 mt-5 flex flex-row-reverse w-full">
              <vs-button @click="saveFantasyName()" :disabled="errors[0]"
                >Salvar</vs-button
              >
            </div>
          </ValidationProvider>
        </div>
      </div>
    </vs-popup>
  </section>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "socios",
  props: {
    segurado: {}
  },
  data() {
    return {
      seguradoFansatyName: {
        id: false,
        list: [],
        name: "",
        selected: {}
      },
      novoNomeFantasia: "",
      popupNomeFantasia: false,
      fantasyNameSelecionado: false,
      perfilHelpers: PerfilHelpers
    };
  },
  watch: {
    segurado: {
      immediate: true,
      handler() {
        this.searchFantasyName();
      }
    }
  },
  methods: {
    ...mapMutations("proposta-module", ["updateFantasyName"]),
    ...mapActions("proposta-module", ["buscaNomeFantasiaPorId"]),
    EditFantasyName(item, index) {
      this.popupNomeFantasia = true;
      this.fantasyNameSelecionado = item;
    },
    searchFantasyName() {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(`/api/person/GetFantasyNames?PersonId=${this.segurado.Id}`)
          .then(response => {
            this.seguradoFansatyName.list = response.data.Response;
          })
          .catch(error => {
            this.cep = "";
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    saveFantasyName() {
      return this.$onpoint.loading(() => {
        let data = {
          PersonId: this.segurado.Id,
          Name: this.novoNomeFantasia
        };
        return axiosInstance({
          method: "post",
          url: "/api/person/SaveFantasyName",
          data: data
        })
          .then(response => {
            this.novoNomeFantasia = "";
            this.popupNomeFantasia = false;
            this.$onpoint.successModal("Nome Fantasia cadastrado com sucesso.");
            this.searchFantasyName();
          })
          .catch(response => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    },
    saveEditFantasyName() {
      return this.$onpoint.loading(() => {
        let data = {
          Id: this.fantasyNameSelecionado.Id,
          Name: this.fantasyNameSelecionado.Name,
          PersonId: this.segurado.Id
        };
        return axiosInstance({
          method: "put",
          url: "/api/person/EditFantasyName",
          data: data
        })
          .then(response => {
            this.fantasyNameSelecionado = {};
            this.popupNomeFantasia = false;
            this.$onpoint.successModal( this.segurado.Entity === 'J' ?  "Nome Fantasia cadastrado com sucesso.": "Nome Social cadastrado com sucesso!");
            this.searchFantasyName();
          })
          .catch(response => {
            this.$onpoint.errorModal(response.data.Errors);
          });
      });
    },
    removeFantasyName(item, index) {
      this.$onpoint.loadingModal("Processando sua solicitação dados", () => {
        return axiosInstance
          .delete(`/api/person/RemoveFantasyName?fantasyNameId=${item.Id}`)
          .then(() => {
            this.searchFantasyName();
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    includeNewFantasyName() {
      this.novoNomeFantasia = "";
      this.popupNomeFantasia = true;
    },
    toggleFantasyName(item, index) {
      //   this.fantasyNameSelecionado.map(line => {
      //     if (line.Id === item.Id) {
      //       this.fantasyNameSelecionado = [];
      //     } else {
      //       this.fantasyNameSelecionado = [];
      //       this.fantasyNameSelecionado[index] = item;
      //     }
      //   });
      this.fantasyNameSelecionado = [];
      this.fantasyNameSelecionado[index] = item;
      this.updateFantasyName(item.Name);
    }
  },
  mounted() {
    this.searchFantasyName();
  }
};
</script>

<style lang="scss" scoped>
.icon-font::before{
  position: relative;
  top: 2px;
}
</style>