<template>
  <div class="px-4 py-4">
    <div class="tab">
      <button
        class="tabLinks cursor-pointer active font-medium w-auto"
        @click="(e) => openTab(e, 'dadosCadastrais')"
      >
        Dados Cadastrais
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="(e) => openTab(e, 'dadosComplementares')"
      >
        Dados Complementares
      </button>
      <button
        class="tabLinks cursor-pointer font-medium w-auto"
        @click="(e) => openTab(e, 'nomefantasia')"
      >
         {{segurado.Entity === 'J' ? 'Nome Fantasia' : 'Nome Social'}}
      </button>
    </div>

    <div id="dadosCadastrais" class="tabContent active">
      <div
        class="flex pt-5"
        v-if="
          perfilHelpers.checkPermission([
            this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS,
          ])
        "
      >
        <label class="pr-2"> Bloquear segurado </label>
        <vx-tooltip
          text="Ao ativar essa opção, o sugurado será bloqueado"
          position="right"
          class="enable-ccg"
        >
          <i class="material-icons">info</i>
        </vx-tooltip>
        <vs-switch
          class="ml-2"
          @input="openModalBlock"
          :value="seguradoDetail.IsBlocked"
        >
          <span slot="on">Sim</span>
          <span slot="off">Não</span>
        </vs-switch>
      </div>

      <div class="vx-row mt-6">
        <vs-input
          class="w-full"
          label="Razão Social"
          disabled="true"
          :value="seguradoDetail.Name"
          :icon="podeEditar ? 'mode_edit' : ''"
          @icon-click="openModalUpdateInsuredName(seguradoDetail.Name)"
          size="large"
        />
      </div>

      <div class="vx-row mt-6">
        <vs-input
          class="w-1/3 pr-3"
          label="Documento"
          disabled="true"
          :value="$utils.maskDocument(seguradoDetail.CpfCnpj)"
          size="large"
        />
        <vs-input
          class="w-1/3 pr-3"
          label="Ramo"
          disabled="true"
          :value="seguradoDetail.IsPrivate ? 'Privado' : 'Publico'"
          :icon="podeEditar ? 'mode_edit' : ''"
          @icon-click="openModalUpdateInsuredBranch(seguradoDetail.IsPrivate)"
          size="large"
        />

        <vs-input
          class="w-1/3 pl-3"
          label="Data de Registro"
          disabled="true"
          :value="
            new Date((seguradoDetail || {}).CreateDate)
              | dateFormat('DD/MM/YYYY')
          "
          size="large"
        />
        <vs-input
          class="w-1/3 pl-3"
          v-if="seguradoDetail.IsBlocked"
          label="Data de bloqueio"
          disabled="true"
          :value="
            new Date((seguradoDetail || {}).BlockDate)
              | dateFormat('DD/MM/YYYY')
          "
          size="large"
        />
      </div>

      <div class="vx-row" v-if="seguradoDetail.IsBlocked">
        <div class="vx-col pt-5 w-full mt-2">
          <label>Justificativa de bloqueio</label>
          <vs-textarea disabled v-model="seguradoDetail.BlockJustify" />
        </div>
      </div>
    </div>

    <div id="dadosComplementares" class="tabContent">
      <list-endereco
        :enderecos="enderecos"
        :podeAlterar="
          perfilHelpers.checkPermission([
            this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS,
          ])
        "
        @remove="removeEndereco"
        @update="atualizarEndereco"
        @save="salvarEndereco"
        v-show="enderecos && enderecos.length"
      ></list-endereco>
    </div>
    <div id="nomefantasia" class="tabContent">
      <FantasySegurado v-if="segurado" :segurado="segurado"></FantasySegurado>
    </div>

    <vs-popup
      class="validacao-data-vigente-modal"
      :title="modalTitle"
      :active.sync="showModalConfirm"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="closeModalConfirm()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="confirmModalConfirm()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      :title="`Atualizar ${(paramModalUpdate || {}).title}`"
      :active.sync="showModalUpdate"
    >
      <div class="vw-row">
        <vs-input
          v-if="typeModalUpdate === 'updateName'"
          class="vx-col w-full mb-3"
          :label="(paramModalUpdate || {}).label"
          v-model="(paramModalUpdate || {}).payload"
          size="large"
        />

        <el-select
          class="large vx-col w-full mb-3 mx-1"
          v-else
          v-model="(paramModalUpdate || {}).payload"
          :placeholder="(paramModalUpdate || {}).label"
        >
          <el-option label="Público" :value="false"> </el-option>
          <el-option label="Privado" :value="true"> </el-option>
        </el-select>

        <div class="vx-col w-full text-right">
          <vs-button
            @click="showModalUpdate = false"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>

          <vs-button
            @click="setModalUpdate()"
            :disabled="
              (paramModalUpdate || {}).payload == null ||
              (paramModalUpdate || {}).payload == undefined
            "
            class="button-primary"
          >
            Salvar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title="Bloquear segurado"
      :active.sync="showModalBlock"
    >
      <div class="vw-row mt-3">
        <div class="vx-col pt-5 w-full mt-2">
          <label class="font-semibold">Justificativa</label>
          <vs-textarea v-model="justifyBlock" />
        </div>

        <div class="vx-col w-full text-right">
          <vs-button @click="closeModalBlock()" class="button-default mr-5">
            Cancelar
          </vs-button>

          <vs-button
            @click="openModalConfirmBlock()"
            :disabled="!justifyBlock"
            class="button-primary"
          >
            Bloquear segurado
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mask, TheMask } from "vue-the-mask";
import ListEndereco from "@/components/enderecos/ListEndereco";
import FantasySegurado from "../../components/fantasy-name/seguradoraSegurado.vue";
import { mapActions, mapGetters } from "vuex";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "dados-gerais",
  directives: { mask },
  components: {
    TheMask,
    ListEndereco,
    FantasySegurado,
  },
  data() {
    return {
      label: "",
      showModalBlock: false,
      showModalConfirm: false,
      showModalNovoEndereco: false,
      showModalUpdate: false,
      typeModalUpdate: "",
      typeModalConfirm: "",
      justifyBlock: "",
      modalTitle: "",
      isBlocked: false,
      novoEndereco: {},
      paramModalConfirm: null,
      paramModalUpdate: null,
      branches: [],

      perfilHelpers: PerfilHelpers,
    };
  },
  computed: {
    ...mapGetters("segurado-module", ["segurado"]),

    enderecos() {
      return this.segurado.Locations || [];
    },

    seguradoDetail() {
      return this.segurado;
    },

    podeEditar() {
      return this.perfilHelpers.checkPermission([
        this.perfilHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS]
      );
    },
  },
  mounted() {},
  methods: {
    ...mapActions("segurado-module", [
      "blockUnblockInsured",
      "saveInsuredLocation",
      "updateInsuredLocation",
      "removePersonLocation",
      "updateInsuredName",
      "updateInsuredBranch",
    ]),
    openTab(event, tabName) {
      [...document.getElementsByClassName("tabContent")].forEach((content) => {
        content.classList.remove("active");
      });

      [...document.getElementsByClassName("tabLinks")].forEach((link) => {
        link.classList.remove("active");
      });

      document.getElementById(tabName).classList.add("active");
      event.currentTarget.classList.add("active");
    },

    openModalBlock(isBlocked) {
      this.showModalBlock = true;
      this.justifyBlock = "";
      this.isBlocked = isBlocked;

      if (this.seguradoDetail.IsBlocked) this.openModalConfirmBlock();
    },

    openModalUpdateInsuredName(name) {
      this.typeModalUpdate = "updateName";
      this.paramModalUpdate = { title: "nome", label: "Nome", payload: name };
      this.showModalUpdate = true;
    },

    openModalUpdateInsuredBranch(ramo) {
      this.typeModalUpdate = "updateBranch";
      this.paramModalUpdate = { title: "ramo", label: "Ramo", payload: ramo };
      this.showModalUpdate = true;
    },

    openModalConfirmBlock() {
      this.modalTitle = this.seguradoDetail.IsBlocked
        ? "Deseja realmente desbloquear o segurado?"
        : "Deseja realmente bloquear o segurado?";

      this.typeModalConfirm = "block";
      this.showModalBlock = false;
      this.showModalConfirm = true;
    },

    confirmModalConfirm() {
      switch (this.typeModalConfirm) {
        case "block":
          this.blockInsured();
          break;
        default:
          break;
      }
    },

    setModalUpdate() {
      switch (this.typeModalUpdate) {
        case "updateName":
          this.salvarInsuredName();
          break;
        case "updateBranch":
          this.salvarInsuredBranch();
          break;
        default:
          break;
      }
    },

    closeModalBlock() {
      this.showModalBlock = false;
      this.typeModalConfirm = "";
    },

    closeModalConfirm() {
      this.showModalConfirm = false;
    },

    async blockInsured() {
      await this.$onpoint.loading(async() => {
        return await this.blockUnblockInsured({
          id: this.seguradoDetail.Id,
          justify: this.justifyBlock,
          isBlocked: this.isBlocked,
        })
          .then((resp) => {
            const action = this.isBlocked ? "bloqueado" : "desbloqueado";
            this.$vs.notify({
              title: "Sucesso",
              text: `Segurado ${action} com sucesso!`,
              color: "success",
            });
            this.showModalConfirm = false;
            this.$emit("load-segurado");
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalConfirm = false;
          });
      });
    },

    async salvarInsuredName() {
      let payload = {
        id: this.seguradoDetail.Id,
        insuredName: this.paramModalUpdate.payload,
      };

      await this.$onpoint.loading(async() => {
        return await this.updateInsuredName(payload)
          .then((resp) => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Segurado atualizado com sucesso!`,
              color: "success",
            });
            this.showModalUpdate = false;
            this.$emit("load-segurado");
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalUpdate = false;
          });
      });
    },

    async salvarInsuredBranch() {
      let payload = {
        id: this.seguradoDetail.Id,
        branch: this.paramModalUpdate.payload,
      };

      await this.$onpoint.loading(async () => {
        return await this.updateInsuredBranch(payload)
          .then((resp) => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Segurado atualizado com sucesso!`,
              color: "success",
            });
            this.showModalUpdate = false;
            this.$emit("load-segurado");
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalUpdate = false;
          });
      });
    },

    async salvarEndereco(location) {
      let payload = {
        uniqueId: this.seguradoDetail.InsuredUniqueId,
        data: {
          ...location,
          PersonId: this.seguradoDetail.Id,
        },
      };

     await this.$onpoint.loading(async() => {
        return await this.saveInsuredLocation(payload)
          .then((resp) => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Endereço inserido com sucesso!`,
              color: "success",
            });
            this.showModalNovoEndereco = false;
            // this.$emit("load-segurado");
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalNovoEndereco = false;
          });
      });
    },

    async atualizarEndereco(location) {
      let payload = {
        uniqueId: this.seguradoDetail.InsuredUniqueId,
        data: {
          ...location,
          PersonId: this.seguradoDetail.Id,
        },
      };

      await this.$onpoint.loading(async () => {
        return await this.updateInsuredLocation(payload)
          .then((resp) => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Endereço editado com sucesso!`,
              color: "success",
            });
            this.showModalConfirm = false;
            this.$emit("load-segurado");
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalConfirm = false;
          });
      });
    },

    async removeEndereco(id) {
      await this.$onpoint.loading(async() => {
        return await this.removePersonLocation({
          uniqueId: this.seguradoDetail.InsuredUniqueId,
          locationId: id,
        })
          .then((resp) => {
            this.$vs.notify({
              title: "Sucesso",
              text: `Endereço removido com sucesso!`,
              color: "success",
            });
            this.showModalConfirm = false;
            this.$emit("load-segurado");
          })
          .catch((err) => {
            this.$onpoint.errorModal(err.response.data.Errors);
            this.showModalConfirm = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  overflow: hidden;
  background-color: white;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: white;
  float: left;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  outline: none;
  padding: 10px 14px;
  transition: 0.3s;
  font-size: inherit;
  color: #848484;
  border-radius: 0 !important;
  font-family: inherit;
}

/* Create an active/current tablink class */
.tab button.active {
  color: rgba(var(--vs-warning), 1);
  border-bottom: 2px solid rgba(var(--vs-warning), 1);
}

/* Style the tab content */
.tabContent {
  display: none;
  padding: 6px 12px;
  border: none;
  border-top: 2px solid #d9d9d9;
  margin-top: -2px;
}

.tabContent.active {
  display: block;
}
.enable-ccg .material-icons {
  font-size: 0.85rem;
  color: rgba(var(--vs-warning), 1);
  font-size: 0.8em;
}
</style>
