var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "locations" } },
    [
      _c(
        "div",
        { staticClass: "flex justify-between mt-3 mb-5" },
        [
          _c("h3", { staticClass: "py-2" }, [_vm._v("Endereços")]),
          _vm.podeAlterar
            ? _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "border" },
                  on: {
                    click: function ($event) {
                      return _vm.addEndereco()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "onpoint-plus icon-font pr-3" }),
                  _vm._v("\n      Adicionar novo\n    "),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.enderecos && _vm.enderecos.length
        ? [
            _c(
              "div",
              { key: _vm.enderecos.length },
              _vm._l(_vm.enderecos, function (dadosEndereco, index) {
                return _c("vs-card", { key: `endereco-${index}` }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center p-2 justify-between" },
                    [
                      _c(
                        "div",
                        { staticClass: "ml-2 mr-3 flex flex-col flex-grow" },
                        [
                          _c("p", { staticClass: "text-base font-semibold" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s((dadosEndereco || {}).Address) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "vs-button",
                        {
                          attrs: { type: "flat" },
                          on: {
                            click: function ($event) {
                              return _vm.setEndereco(dadosEndereco, index)
                            },
                          },
                        },
                        [
                          _c("i", {
                            class: {
                              "onpoint-caret-down icon-font":
                                index !== _vm.enderecoIndex,
                              "onpoint-caret-up icon-font":
                                index == _vm.enderecoIndex,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  index === _vm.enderecoIndex && _vm.endereco
                    ? _c(
                        "div",
                        [
                          _c("form-endereco", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: index === _vm.enderecoIndex,
                                expression: "index === enderecoIndex",
                              },
                            ],
                            attrs: {
                              textCancelButton: "Excluir endereço",
                              dadosEndereco: _vm.endereco,
                              showOkButton: _vm.podeAlterar,
                              disabled: !_vm.podeAlterar,
                            },
                            on: {
                              cancel: _vm.openModalConfirmRemoveEndereco,
                              save: _vm.update,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
              1
            ),
          ]
        : [_vm._m(0)],
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            title: "Deseja realmente remover esse endereço?",
            active: _vm.showModalConfirm,
          },
          on: {
            "update:active": function ($event) {
              _vm.showModalConfirm = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        _vm.showModalConfirm = false
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.remove()
                      },
                    },
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "validacao-data-vigente-modal",
          attrs: {
            title: "Cadastrar endereço",
            active: _vm.showModalNovoEndereco,
          },
          on: {
            "update:active": function ($event) {
              _vm.showModalNovoEndereco = $event
            },
          },
        },
        [
          _vm.novoEndereco
            ? _c("form-endereco", {
                attrs: {
                  dadosEndereco: _vm.novoEndereco,
                  showCancelButton: true,
                  showOkButton: true,
                  textCancelButton: "Cancelar",
                },
                on: { cancel: _vm.closeModal, save: _vm.salvarEndereco },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32",
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("map"),
          ]),
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n        Nenhum endereço encontrado para este segurado\n      "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }