<template>
  <section class="proposal-documents">
    <div
      class="vx-row"
      v-if="
        profileHelpers.checkPermission([
          profileHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
        ])
      "
    >
      <div class="vx-col pt-5 text-right w-full mt-2">
        <vs-button @click.native="openDialogUpload()" color="primary">
          <span class="font-semibold pr-2 pl-2">
            Upload de documento
          </span>
        </vs-button>
      </div>
    </div>
    <vs-table
      class="mt-10 table-available"
      :data="validDocuments"
      noDataText="Nenhum arquivo foi cadastrado"
    >
      <template slot="thead">
        <vs-th>
          Nome
        </vs-th>
        <vs-th>
          Data de inserção
        </vs-th>
        <vs-th>
          Ações
        </vs-th>
      </template>
      <template>
        <vs-tr
          :data="document"
          :key="indextr"
          v-for="(document, indextr) in validDocuments"
        >
          <vs-td>{{ validDocuments[indextr].Name }}</vs-td>
          <vs-td>{{
            validDocuments[indextr].CreateDate | moment("DD/MM/YYYY HH:mm")
          }}</vs-td>
          <vs-td>
            <vs-button
              :disabled="!validDocuments[indextr].TotalArchives"
              class="p-0"
              color="dark"
              type="flat"
              title="Download do documento"
              @click.native="downloadDocument(validDocuments[indextr].UniqueId)"
            >
              <i class="onpoint-download-simple icon-font"></i>
            </vs-button>
            <vs-button
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
                ])
              "
              class="p-0"
              color="dark"
              type="flat"
              title="Editar nome do documento"
              @click.native="
                openDialogEditDocument(validDocuments[indextr].UniqueId)
              "
            >
              <i class="onpoint-pencil-simple-line icon-font"></i>
            </vs-button>
            <vs-button
              v-if="
                profileHelpers.checkPermission([
                  profileHelpers.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS
                ])
              "
              class="p-0"
              color="dark"
              type="flat"
              title="Excluir documento"
              @click.native="
                openDialogDeleteDocument(
                  validDocuments[indextr].UniqueId,
                  validDocuments[indextr].Name
                )
              "
            >
              <i class="onpoint-trash icon-font"></i>
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div
      v-show="validDocuments.length > 0"
      class="download-all"
      @click="downloadAllDocuments()"
    >
      <span>Baixar todos</span><i class="onpoint-download icon-font"></i>
    </div>

    <h4>Documentos excluidos</h4>
    <vs-table
      class="mt-10 table-trash"
      :data="trashDocuments"
      noDataText="Nenhum arquivo foi excluído"
    >
      <template slot="thead">
        <vs-th>
          Nome
        </vs-th>
        <!-- <vs-th>
          Origem
        </vs-th> -->
        <vs-th>
          Data de inserção
        </vs-th>
        <!-- <vs-th>
          Excluido por
        </vs-th> -->
        <vs-th>
          Data da exclusão
        </vs-th>
        <vs-th>
          Ações
        </vs-th>
      </template>
      <template>
        <vs-tr
          :data="document"
          :key="indextr"
          v-for="(document, indextr) in trashDocuments"
        >
          <vs-td>{{ trashDocuments[indextr].Name }}</vs-td>
          <!-- <vs-td>{{ trashDocuments[indextr].CreateByPerson.Name }}</vs-td> -->
          <vs-td>{{
            trashDocuments[indextr].CreateDate
              ? $utils.dateToDDMMYYYY(trashDocuments[indextr].CreateDate)
              : "-"
          }}</vs-td>
          <!-- <vs-td>{{ trashDocuments[indextr].UpdateByPerson.Name }}</vs-td> -->
          <vs-td>{{
            trashDocuments[indextr].CreateDate | moment("DD/MM/YYYY HH:mm")
          }}</vs-td>
          <vs-td
            ><p
              class="restore"
              @click="restoreDocument(trashDocuments[indextr].UniqueId)"
            >
              Restaurar
            </p></vs-td
          >
        </vs-tr>
      </template>
    </vs-table>
    <base64Upload
      v-if="openDialogAddDocumente"
      :modalUpload="openDialogAddDocumente"
      :documentoName="''"
      :insertName="true"
      @callBackUpload="callBackUpload"
      @closeModal="closeModal"
    />
    <!-- <vs-popup
      class="popup-upload-document z-index-modal"
      title="Upload de documento"
      :active.sync="openDialogAddDocumente"
    >
      <div class="vx-row flex justify-center">
        <div>
          <az-upload
            :singleUpload="true"
            :controller="uploadDocumentController"
          />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Nomear documento:</p>
          <vs-input class="w-full" v-model="documentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end p-4">
        <div>
          <vs-button
            @click.native="uploadDocumento(documentName)"
            color="primary"
          >
            <span class="font-semibold pr-2 pl-2">
              Enviar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup> -->
    <vs-popup
      class="popup-edit-documents z-index-modal"
      title="Editar nome"
      :active.sync="openEditDocument"
    >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="flex font-semibold">Informe o novo nome</p>
          <vs-input class="w-full" v-model="newDocumentName" size="large" />
        </div>
      </div>
      <div class="vx-row flex justify-end">
        <div>
          <vs-button @click.native="editDocument()" color="primary">
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      class="popup-delete-documents z-index-modal"
      title="Deletar arquivo"
      :active.sync="openDeleteDocument"
    >
      <div class="vx-row flex justify-center">
        <div>
          <h4>Confirma a ação de deleter o documento?</h4>
        </div>
      </div>
      <div class="vx-row flex justify-center">
        <div class="document-name">{{ documentName }}</div>
      </div>
      <div class="vx-row flex justify-center">
        <div>
          <vs-button
            color="dark"
            class="mr-10"
            @click.native="openDeleteDocument = false"
          >
            <span class="font-semibold pr-2 pl-2">
              Voltar
            </span>
          </vs-button>
          <vs-button
            @click.native="deleteDocument(RequestDocumentUniqueId)"
            color="dark"
          >
            <span class="font-semibold pr-2 pl-2">
              Confirmar
            </span>
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import Upload from "@/components/az-upload/AzUpload.vue";
import { kanbanProvider } from "@/providers/kanban.provider";
import base64Upload from "@/components/base64-Upload/index.vue";
import PerfilHelpers from "@/helpers/PerfilHelpers";
import instance from "@/axios";
export default {
  name: "policyholder-documents",
  components: {
    Upload,
    base64Upload
  },
  watch: {
    policyHolderDocuments(newVal) {
      this.validDocuments = newVal;
    },
    policyHolderDocumentsDeleted(newVal) {
      this.trashDocuments = newVal;
    }
  },
  data() {
    return {
      openDialogAddDocumente: false,
      openEditDocument: false,
      openDeleteDocument: false,
      trashDocuments: [],
      validDocuments: [],
      documentName: "",
      newDocumentName: "",
      uploadDocumentController: {},
      RequestDocumentUniqueId: null,
      listChanged: false,

      profileHelpers: PerfilHelpers
    };
  },
  mounted() {
    this.getPolicyHolderDocuments();
  },
  methods: {
    ...mapActions("segurado-module", [
      "uploadDocument",
      "deleteRequestDocument",
      "getDocumentoPorId",
      "editNameDocument",
      "restoreDocument",
      "createDocument",
      "getDocuments"
    ]),
    closeModal() {
      this.openDialogAddDocumente = false;
    },
    callBackUpload(callback) {
      let uniqueId = this.$route.params.seguradoUniqueId;
      this.openDialogAddDocumente = false;
      return instance({
        method: "post",
        url: "api/Insured/CreateRequestDocument",
        data: {
          Document: callback.documentoText,
          UniqueId: uniqueId
        }
      })
        .then(data => {
          let request = {
            RequestDocumentUniqueId:
              data.data.Response.RequestDocuments.UniqueId,
            UniqueId: uniqueId,
            files: [
              {
                name: callback.callback.data.Response.Filename,
                uniqueIdentifier: callback.callback.data.Response.UniqueId,
                extension: callback.callback.data.Response.Extension,
                relativePath: callback.callback.data.Response.Filename
              }
            ]
          };
          return this.uploadDocument(request).then(response => {
            this.$onpoint.successModal("Documento enviado com sucesso.");
            this.openDialogAddDocumente = false;
            this.getPolicyHolderDocuments();
          });
        })
        .catch(ex => {
          this.$onpoint.errorModal(ex);
        });
    },
    async getPolicyHolderDocuments() {
      await this.$onpoint.loading(async () => {
        return await this.getDocuments(this.$route.params.seguradoUniqueId).then(
          res => {
            this.validDocuments = res.RequestDocuments;
            this.trashDocuments = res.RequestDocumentsDeleted;
          }
        );
      });
    },
    downloadDocument(RequestDocumentUniqueId) {
      this.$onpoint.loading(() => {
        return kanbanProvider
          .getRequestDocumentArchives(RequestDocumentUniqueId)
          .then(resp => {
            if (resp.length === 0)
              return this.$onpoint.errorModal("Nenhum anexo nesse documento!");
            resp.map(document => {
              kanbanProvider
                .getArchiveLinkToDownload(document.UniqueId)
                .then(data => {
                  window.open(data, "_blank");
                })
                .catch(ex => {
                  this.$onpoint.errorModal(ex);
                });
            });
          });
      });
    },
    editDocument() {
      this.$onpoint.loadingModal("Estamos alterando seu documento.", () => {
        return this.editNameDocument({
          RequestDocumentUniqueId: this.RequestDocumentUniqueId,
          UniqueId: this.$route.params.seguradoUniqueId,
          Name: this.newDocumentName
        }).then(resp => {
          this.validDocuments = resp.RequestDocuments;
          this.openEditDocument = false;
          this.$onpoint.successModal("Nome do documento alterado com sucesso!");
        });
      });
    },
    downloadAllDocuments() {
      this.validDocuments.map(document => {
        if (document.TotalArchives) this.downloadDocument(document.UniqueId);
      });
    },
    async deleteDocument() {
      await this.$onpoint.loadingModal("Estamos deletando seu documento.", async () => {
        return await this.deleteRequestDocument({
          UniqueId: this.$route.params.seguradoUniqueId,
          RequestDocumentUniqueId: this.RequestDocumentUniqueId
        })
          .then(data => {
            this.validDocuments = data.RequestDocuments;
            this.trashDocuments = data.RequestDocumentsDeleted;
            this.openDeleteDocument = false;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    restoreDocument(RequestDocumentUniqueId) {
      this.$onpoint.loadingModal("Estamos restaurando seu documento.", () => {
        return this.restoreDocument({
          UniqueId: this.$route.params.seguradoUniqueId,
          RequestDocumentUniqueId: RequestDocumentUniqueId
        })
          .then(data => {
            this.validDocuments = data.RequestDocuments;
            this.trashDocuments = data.RequestDocumentsDeleted;
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
    openDialogUpload() {
      this.documentName = "";
      this.openDialogAddDocumente = true;
    },
    openDialogEditDocument(RequestDocumentUniqueId) {
      this.newDocumentName = "";
      this.openEditDocument = true;
      this.RequestDocumentUniqueId = RequestDocumentUniqueId;
    },
    openDialogDeleteDocument(RequestDocumentUniqueId, documentName) {
      this.documentName = this.openDeleteDocument = true;
      this.RequestDocumentUniqueId = RequestDocumentUniqueId;
      this.documentName = documentName;
    }
  }
};
</script>
<style lang="scss">
.table-available table thead tr {
  background: rgba(var(--vs-primary), 1);
  color: #fff;
}
.table-trash table thead tr {
  background: #e4e1e1;
  color: #636363;
}
.con-vs-popup .vs-popup {
  width: 500px;
  padding: 30px;
}
.document-name {
  background: #f8f8f8;
  padding: 20px 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: rgba(var(--vs-primary), 1);
}
.download-all {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  color: rgba(var(--vs-primary), 1);
  span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: rgba(var(--vs-primary), 1);
  }
}
.restore {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: rgba(var(--vs-primary), 1);
}
.z-index-modal {
  z-index: 52999;
}
</style>

<style lang="scss" scoped>
.icon-font::before {
  font-size: 16px !important;
  margin: 10px;
  position: relative;
  right: 1px;
  top: 1px;
}
</style>