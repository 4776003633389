<template>
  <div class="cadastro-segurado new-tabs">
    <div class="breadcrumb mb-6">
      <breadcrumb
        v-if="(segurado || {}).Name"
        title="Segurado"
        :actualPage="(segurado || {}).Name"
        previousPage="segurados"
        previousPageTitle="Lista de segurados"
      />
    </div>

    <div class="perfil-segurado">
      <vs-tabs color="warning">
        <vs-tab label="Cadastro Completo">
          <dados-gerais @load-segurado="carregarPagina()" v-if="segurado.Id" />
        </vs-tab>

        <vs-tab label="Sócios / Administradores">
          <TableSocios :Socios="segurado.UniqueId" :TypeText="undefined" />
        </vs-tab>

        <vs-tab label="Apólices">
          <apolices-segurado :segurado="segurado" v-if="segurado.Id" />
        </vs-tab>

        <vs-tab label="Documentos">
          <insured-documents />
        </vs-tab>

        <vs-tab label="Histórico de Alterações" v-if="segurado.Id">
          <historico-alteracao :id="segurado.Id" />
        </vs-tab>

        <vs-tab label="Histórico de Consultas" v-if="exibirHistoricoConsultasSeguradoPorBureau && segurado.Id">
          <HistoricoConsultasSegurado :document="segurado.CpfCnpj" />
        </vs-tab>

        <vs-tab label="Histórico de Consultas" v-else>
          <historico-consultas :document="segurado.CpfCnpj" />
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import DadosGerais from "./DadosGerais.vue";
import Documentos from "./Documentos";
import TableSocios from "@/components/vs-table/Table-Socios.vue";
import ApolicesSegurado from "./ApolicesSegurado.vue";
// import HistoricoAlteracao from "./HistoricoAlteracao.vue";
// import HistoricoConsultas from "@/components/historico-consultas/HistoricoConsultas.vue";
import HistoricoAlteracao from "./HistoricoAlteracao.vue";
import HistoricoConsultas from "../../components/historico-consultas/HistoricoConsultas.vue";
import HistoricoConsultasSegurado from "./HistoricoConsultasSegurado.vue";
import environment from "@/environment";
import commonProvider from "@/providers/common-provider.js";
import * as Permissoes from "@/utils/permissions.js";

export default {
  components: {
    breadcrumb: Breadcrumb,
    DadosGerais,
    ApolicesSegurado,
    "insured-documents": Documentos,
    TableSocios,
    HistoricoConsultas,
    HistoricoAlteracao,
    HistoricoConsultasSegurado
  },
  data() {
    return {
      seguradoUniqueIdParam: "",
      isEssor: false,
      permissoes: []
    };
  },
  async mounted() {
    await this.$onpoint.loading(async () => {
      await this.carregarPagina();
      await this.obterModuloPermissoes();
    });
  },
  created() {
    this.$appInsights.trackPageView({
      name: "detalhe-segurado",
      url: window.location.href
    });

    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    let Essor = parseInt(environment.CompanyId.Essor);
    this.isEssor = portalConfig.CompanyId == Essor ? true : false
  },
  destroyed() {
    this.updateSegurado(undefined);
  },
  computed: {
    ...mapGetters("segurado-module", ["segurado"]),

    exibirHistoricoConsultasSeguradoPorBureau() {
      return this.permissoes.includes("ExibirHistoricoConsultasBureau");
    },
  },
  methods: {
    ...mapActions("segurado-module", ["carregarSegurado"]),

    ...mapMutations("segurado-module", ["updateSegurado"]),

    async carregarPagina() {
      await this.$onpoint.loading(async () => {
        return await this.carregarSegurado(this.$route.params.seguradoUniqueId)
          .then(res => this.$forceUpdate())
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },

    async obterModuloPermissoes() {
      this.permissoes = [];
      try {
        this.permissoes = await commonProvider.obterPermissoesModulo(Permissoes.Modulo.SEGURADO);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },
  }
};
</script>
<style lang="scss">
.perfil-segurado {
  .con-vs-tabs {
    display: flex;
  }

  .con-ul-tabs {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 250px;
    padding: 50px 20px;
    margin-bottom: 10px;
  }

  .line-vs-tabs {
    margin-top: 50px;
    margin-left: 20px;
  }

  .vs-tabs--li button.vs-tabs--btn {
    margin-top: 30px;
    width: auto;
  }

  .vs-tabs--ul {
    box-shadow: none;
    flex-direction: column;
  }

  .vs-tabs--li {
    padding-right: 15px;
  }

  .con-slot-tabs {
    background: white;
    border-radius: 10px;
    margin: 0 20px 10px 5px;
  }
}
</style>
