<template>
  <div id="locations">
    <div class="flex justify-between mt-3 mb-5">
      <h3 class="py-2">Endereços</h3>
      <vs-button
        color="primary"
        type="border"
        @click="addEndereco()"
        v-if="podeAlterar"
      >
        <i class="onpoint-plus icon-font pr-3"></i>
        Adicionar novo
      </vs-button>
    </div>

    <template v-if="enderecos && enderecos.length">
      <div :key="enderecos.length">
        <vs-card
          v-for="(dadosEndereco, index) in enderecos"
          :key="`endereco-${index}`"
        >
          <div class="flex items-center p-2 justify-between">
            <div class="ml-2 mr-3 flex flex-col flex-grow">
              <p class="text-base font-semibold">
                {{ (dadosEndereco || {}).Address }}
              </p>
            </div>

            <vs-button type="flat" @click="setEndereco(dadosEndereco, index)"
              ><i
                :class="{
                  'onpoint-caret-down icon-font': index !== enderecoIndex,
                  'onpoint-caret-up icon-font': index == enderecoIndex
                }"
              ></i
            ></vs-button>
          </div>

          <div v-if="index === enderecoIndex && endereco">
            <form-endereco
              v-show="index === enderecoIndex"
              textCancelButton="Excluir endereço"
              :dadosEndereco="endereco"
              :showOkButton="podeAlterar"
              :disabled="!podeAlterar"
              @cancel="openModalConfirmRemoveEndereco"
              @save="update"
            ></form-endereco>
          </div>
        </vs-card>
      </div>
    </template>

    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
        <p><span class="material-icons text-6xl">map</span></p>
        <p class="text-center">
          Nenhum endereço encontrado para este segurado
        </p>
      </div>
    </template>

    <vs-popup
      class="validacao-data-vigente-modal"
      title="Deseja realmente remover esse endereço?"
      :active.sync="showModalConfirm"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button
            @click="showModalConfirm = false"
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button @click="remove()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title="Cadastrar endereço"
      :active.sync="showModalNovoEndereco"
    >
      <form-endereco
        v-if="novoEndereco"
        :dadosEndereco="novoEndereco"
        :showCancelButton="true"
        :showOkButton="true"
        textCancelButton="Cancelar"
        @cancel="closeModal"
        @save="salvarEndereco"
      ></form-endereco>
    </vs-popup>
  </div>
</template>

<script>
import FormEndereco from "./FormEndereco.vue";
export default {
  data() {
    return {
      endereco: {},
      novoEndereco: null,
      enderecoIndex: null,
      paramModalConfirm: null,
      showModalConfirm: false,
      showModalNovoEndereco: false
    };
  },
  props: {
    enderecos: { type: Array, default: () => [] },
    podeAlterar: { type: Boolean, default: true }
  },
  components: { FormEndereco },
  methods: {
    update(value) {
      this.$emit("update", value);
    },

    remove() {
      this.showModalConfirm = false;
      this.enderecoIndex = false;
      this.$emit("remove", this.paramModalConfirm);
    },

    setEndereco(endereco, index) {
      if (index !== this.enderecoIndex) {
        this.endereco = Object.assign({}, endereco);
        this.enderecoIndex = index;
      } else {
        this.enderecoIndex = null;
      }
      this.$forceUpdate();
    },

    openModalConfirmRemoveEndereco(id) {
      this.showModalConfirm = true;
      this.paramModalConfirm = id;
    },

    closeModal() {
      this.showModalNovoEndereco = false;
    },

    addEndereco() {
      this.showModalNovoEndereco = true;
      this.novoEndereco = {
        StateProvinceId: "",
        CityId: "",
        ZipCode: "",
        AddressName: "",
        Number: "",
        Complement: "",
        Neighborhood: "",
        IsMain: this.enderecos.filter(x => x.IsMain).length == 0,
        Id: ""
      };
    },

    salvarEndereco(value) {
      this.showModalNovoEndereco = false;

      setTimeout(() => {
        this.novoEndereco = null;
      }, 400);

      this.$emit("save", value);
    }
  }
};
</script>

<style lang="scss">
#locations {
  .con-vs-card {
    box-shadow: 0 0;
    .vs-card--content {
      background: #f8f8f8;
      background: #f8f8f8;
      /* grayscale/gray-2 */

      border: 1px solid #e4e1e1;
      box-sizing: border-box;
      .vs-collapse {
        padding: 0;

        .vs-collapse-item {
          background: #f8f8f8;
          cursor: auto !important;

          .vs-collapse-item--header {
            display: none;
          }
        }

        .open-item {
          .vs-collapse-item--content {
            padding: 1rem;
            max-height: max-content !important;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.icon-font::before{
  position: relative;
  top: 2px;
}
</style>