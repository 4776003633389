var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-4" },
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _vm.listaApolices.length > 0
            ? [
                _c(
                  "vs-table",
                  {
                    staticClass: "table-list",
                    attrs: {
                      data: _vm.listaApolices,
                      hoverFlat: true,
                      noDataText: "Nenhuma apólice encontrada",
                      maxHeight: "72vh",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ data }) {
                            return _vm._l(data, function (item, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr, attrs: { data: item } },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data: data[indextr].PolicyNumber,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            data[indextr].PolicyNumber || "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: data[indextr].Modality },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(data[indextr].Modality) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.EndorsementCount },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "flex" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.EndorsementCount > 0
                                                ? "Sim"
                                                : "Não"
                                            ) + "\n                "
                                          ),
                                          item.EndorsementCount > 0
                                            ? _c("vs-button", {
                                                staticClass:
                                                  "visualizar-endosso",
                                                attrs: {
                                                  radius: "",
                                                  color: "primary",
                                                  type: "flat",
                                                  icon: "remove_red_eye",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showEndorsementListModal(
                                                      item.PolicyUniqueId
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        data: data[indextr].PolicyHolderName,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            data[indextr].PolicyHolderName
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.PolicyStatusId },
                                    },
                                    [
                                      item.PolicyStatusId > 0
                                        ? _c(
                                            "vs-chip",
                                            {
                                              attrs: {
                                                color: _vm._f("setColorStatus")(
                                                  _vm.getStatus(
                                                    item.PolicyStatusId
                                                  )
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getStatus(
                                                      item.PolicyStatusId
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : [_vm._v("-")],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.IssueDate },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.IssueDate
                                              ? _vm.$utils.dateToDDMMYYYY(
                                                  item.IssueDate
                                                )
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { data: item.EndDate },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.EndDate
                                              ? _vm.$utils.dateToDDMMYYYY(
                                                  item.EndDate
                                                )
                                              : "-"
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { staticClass: "text-center" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            item.StartDate && item.EndDate
                                              ? `${_vm
                                                  .getPercent(
                                                    item.StartDate,
                                                    item.EndDate,
                                                    item.PolicyStatusId
                                                  )
                                                  .toFixed(0)}%`
                                              : ""
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "vs-progress",
                                        {
                                          attrs: {
                                            percent: _vm.getPercent(
                                              item.StartDate,
                                              item.EndDate,
                                              item.PolicyStatusId
                                            ),
                                            height: 8,
                                            color: _vm.getPercentColor(
                                              _vm.getPercent(
                                                item.StartDate,
                                                item.EndDate,
                                                item.PolicyStatusId
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("primary")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "btn-action-apolice",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "dark",
                                                type: "flat",
                                                title: "Ações",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "onpoint-dots-three-vertical icon-dots-three-vertical",
                                                attrs: { role: "button" },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticClass:
                                                "btn-action-apolice-options",
                                            },
                                            [
                                              _c("vs-dropdown-item", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-base px-6 py-2",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadApoliceClick(
                                                          data[indextr]
                                                            .OriginalProposalUniqueId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Visualizar apólice"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .DOWNLOAD_DO_LAUDO_DE_EMISSAO,
                                                ]
                                              ) &&
                                              data[indextr].PolicyStatusId == 2
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.downloadLaudoEmissaoClick(
                                                            data[indextr]
                                                              .OriginalProposalUniqueId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Visualizar laudo de emissão"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.profileHelpers.checkPermission(
                                                [
                                                  _vm.profileHelpers
                                                    .menuFuncionalitiesEnum
                                                    .CANCELAR_APOLICE_MANUALMENTE,
                                                ]
                                              ) &&
                                              data[indextr].PolicyStatusId == 2
                                                ? _c(
                                                    "vs-dropdown-item",
                                                    {
                                                      attrs: { divider: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.apoliceSelecionadaCancelamento =
                                                            data[
                                                              indextr
                                                            ].PolicyNumber
                                                          _vm.openModalCancelamento = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-base px-6 py-2",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Cancelar apólice manualmente"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          },
                        },
                      ],
                      null,
                      false,
                      566741240
                    ),
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [_vm._v("Número")]),
                        _c("vs-th", { attrs: { "sort-key": "Modality" } }, [
                          _vm._v("Modalidade"),
                        ]),
                        _c("vs-th", { staticClass: "text-center" }, [
                          _vm._v("Contém endosso?"),
                        ]),
                        _c(
                          "vs-th",
                          { attrs: { "sort-key": "PolicyHolderName" } },
                          [_vm._v("Tomador")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "PolicyStatusId" },
                          },
                          [_vm._v("Status")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "IssueDate" },
                          },
                          [_vm._v("Emissão")]
                        ),
                        _c(
                          "vs-th",
                          {
                            staticClass: "text-center",
                            attrs: { "sort-key": "EndDate" },
                          },
                          [_vm._v("Vencimento")]
                        ),
                        _c("vs-th", { staticClass: "text-center" }, [
                          _vm._v("Vigência"),
                        ]),
                        _c("vs-th"),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            : [_vm._m(0)],
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.openModalCancelamento,
            title: "Deseja realmente cancelar esta apólice?",
          },
          on: {
            "update:active": function ($event) {
              _vm.openModalCancelamento = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vw-row mt-3" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "button-default mr-5",
                    on: {
                      click: function ($event) {
                        _vm.openModalCancelamento = false
                        _vm.apoliceSelecionadaCancelamento = null
                      },
                    },
                  },
                  [_vm._v("\n          Cancelar\n        ")]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-primary",
                    on: {
                      click: function ($event) {
                        return _vm.internalCancelPolicy()
                      },
                    },
                  },
                  [_vm._v("\n          Confirmar\n        ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32",
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description"),
          ]),
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n          Nenhuma apólice encontrada para este segurado\n        "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }