<template>
  <section class="mt-4">
    <div class="table-container">
      <template v-if="listaApolices.length > 0">
        <vs-table
          class="table-list"
          :data="listaApolices"
          :hoverFlat="true"
          noDataText="Nenhuma apólice encontrada"
          maxHeight="72vh"
        >
          <template slot="thead">
            <vs-th>Número</vs-th>
            <vs-th sort-key="Modality">Modalidade</vs-th>
            <vs-th class="text-center">Contém endosso?</vs-th>
            <vs-th sort-key="PolicyHolderName">Tomador</vs-th>
            <vs-th sort-key="PolicyStatusId" class="text-center">Status</vs-th>
            <vs-th sort-key="IssueDate" class="text-center">Emissão</vs-th>
            <vs-th sort-key="EndDate" class="text-center">Vencimento</vs-th>
            <vs-th class="text-center">Vigência</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].PolicyNumber" class="text-center">
                {{ data[indextr].PolicyNumber || "-" }}
              </vs-td>

              <vs-td :data="data[indextr].Modality" class="text-center">
                {{ data[indextr].Modality }}
              </vs-td>

              <vs-td :data="item.EndorsementCount" class="text-center">
                <span class="flex"
                  >{{ item.EndorsementCount > 0 ? "Sim" : "Não" }}
                  <vs-button
                    radius
                    class="visualizar-endosso"
                    color="primary"
                    type="flat"
                    icon="remove_red_eye"
                    v-if="item.EndorsementCount > 0"
                    @click="showEndorsementListModal(item.PolicyUniqueId)"
                  ></vs-button>
                </span>
              </vs-td>

              <vs-td :data="data[indextr].PolicyHolderName" class="text-center">
                {{ data[indextr].PolicyHolderName }}
              </vs-td>

              <vs-td :data="item.PolicyStatusId" class="text-center">
                <vs-chip
                  v-if="item.PolicyStatusId > 0"
                  :color="getStatus(item.PolicyStatusId) | setColorStatus"
                >
                  {{ getStatus(item.PolicyStatusId) }}
                </vs-chip>
                <template v-else>-</template>
              </vs-td>

              <vs-td :data="item.IssueDate" class="text-center">
                {{
                  item.IssueDate ? $utils.dateToDDMMYYYY(item.IssueDate) : "-"
                }}
              </vs-td>

              <vs-td :data="item.EndDate" class="text-center">
                {{ item.EndDate ? $utils.dateToDDMMYYYY(item.EndDate) : "-" }}
              </vs-td>

              <vs-td class="text-center">
                {{
                  item.StartDate && item.EndDate
                    ? `${getPercent(
                        item.StartDate,
                        item.EndDate,
                        item.PolicyStatusId
                      ).toFixed(0)}%`
                    : ""
                }}
                <vs-progress
                  :percent="
                    getPercent(
                      item.StartDate,
                      item.EndDate,
                      item.PolicyStatusId
                    )
                  "
                  :height="8"
                  :color="
                    getPercentColor(
                      getPercent(
                        item.StartDate,
                        item.EndDate,
                        item.PolicyStatusId
                      )
                    )
                  "
                  >primary</vs-progress
                >
              </vs-td>

              <vs-td>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-apolice"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu class="btn-action-apolice-options">
                    <vs-dropdown-item>
                      <p
                        class="text-base px-6 py-2"
                        @click="
                          downloadApoliceClick(
                            data[indextr].OriginalProposalUniqueId
                          )
                        "
                      >
                        <span>Visualizar apólice</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      divider
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .DOWNLOAD_DO_LAUDO_DE_EMISSAO
                        ]) &&
                          data[indextr].PolicyStatusId == 2
                      "
                      @click="
                        downloadLaudoEmissaoClick(
                          data[indextr].OriginalProposalUniqueId
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Visualizar laudo de emissão</span>
                      </p>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                      divider
                      v-if="
                        profileHelpers.checkPermission([
                          profileHelpers.menuFuncionalitiesEnum
                            .CANCELAR_APOLICE_MANUALMENTE
                        ]) && data[indextr].PolicyStatusId == 2
                      "
                      @click="
                        apoliceSelecionadaCancelamento =
                          data[indextr].PolicyNumber;
                        openModalCancelamento = true;
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Cancelar apólice manualmente</span>
                      </p>
                    </vs-dropdown-item>

                    <!-- <vs-dropdown-item
                      v-if="item.PolicyStatusId == 2"
                      divider
                      @click="goToCancelamento(item.PolicyUniqueId)"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Cancelar apólice</span>
                      </p>
                    </vs-dropdown-item> -->
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p class="text-center">
            Nenhuma apólice encontrada para este segurado
          </p>
        </div>
      </template>
    </div>

    <!-- <listar-endosso
      :endossoModal="endossoModal"
      @fecharListarEndossoModal="endossoModal = false"
    /> -->

    <vs-popup
      :active.sync="openModalCancelamento"
      title="Deseja realmente cancelar esta apólice?"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button
            @click="
              openModalCancelamento = false;
              apoliceSelecionadaCancelamento = null;
            "
            class="button-default mr-5"
          >
            Cancelar
          </vs-button>
          <vs-button @click="internalCancelPolicy()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
// import ListarEndosso from "../endosso/components/ListarEndosso";

import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "@/mixins/optionsStaticMixin";

import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "apolices-segurado",
  // components: { ListarEndosso },
  props: {
    segurado: {
      type: Object,
      required: true
    }
  },
  mixins: [optionsStaticMixin],
  mounted() {
    this.listarApolicesSegurado();
  },
  data() {
    return {
      endossoModal: false,
      listaApolices: [],
      apoliceSelecionadaCancelamento: null,
      openModalCancelamento: false,

      profileHelpers: PerfilHelpers
    };
  },
  computed: {
    ...mapGetters("endosso-module", ["endossoList"])
  },
  methods: {
    ...mapActions("endosso-module", ["getListEndorsementPolicy"]),
    ...mapActions("apolice-module", [
      "searchApolicesSegurado",
      "downloadApolice",
      "postInternalCancelPolicy"
    ]),
    ...mapActions("propostas-module", [
      "downloadBoleto",
      "downloadLaudoEmissao"
    ]),

    listarApolicesSegurado() {
      this.$onpoint.loading(() => {
        return this.searchApolicesSegurado(this.$route.params.seguradoUniqueId)
          .then(response => {
            this.listaApolices = response;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    downloadApoliceClick(propostaUniqueId) {

      let payload = {
        propostaUniqueId: propostaUniqueId,
        endorsement: false
      };

      this.$onpoint.loading(() => {
        return this.downloadApolice(payload)
          .then(urlToDownload => {
            window.open(urlToDownload, "_self");
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async downloadBoletoClick(propostaUniqueId) {
      await this.$onpoint.loading( async() => {
        return await this.downloadBoleto(propostaUniqueId)
          .then(linksToDownload => {
            //deve-se desabilitar o bloqueio de popups no navegador, senão apenas o primeiro item será baixado -> Lucaxx
            linksToDownload.forEach(element => {
              window.open(element);
            });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async downloadLaudoEmissaoClick(propostaUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.downloadLaudoEmissao(propostaUniqueId)
          .then(urlToDownloadIssuanceReport => {
            window.open(urlToDownloadIssuanceReport, "_self");
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    internalCancelPolicy() {
      this.$onpoint.loading(() => {
        this.openModalCancelamento = false;
        return this.postInternalCancelPolicy(
          this.apoliceSelecionadaCancelamento
        )
          .then(response => {
            this.listarApolicesSegurado();
            this.$onpoint.successModal("Operação realizada com sucesso!");
          })
          .catch(err => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    goToCancelamento(policyUniqueId) {
      this.$router.push({
        name: "cancelamento-apolice",
        params: { policyUniqueId }
      });
    },

    getStatus(statusId) {
      const status = this.policyStatusOptionsMixin.filter(status => {
        if (status.id == statusId) return status;
      });

      return status[0].name;
    },

    getPercent(startDate, endDate, statusId) {
      if (!endDate || !startDate || statusId == 3) return 100;

      const timeNow = new Date().getTime();
      const timeStart = new Date(startDate).getTime();
      const timeEnd = new Date(endDate).getTime();

      if (timeStart > timeNow || statusId == 1) return 0;
      else if (timeNow > timeEnd) return 100;
      else {
        return ((timeEnd - timeNow) * 100) / timeEnd;
      }
    },

    getPercentColor(percent) {
      if (percent < 33) return "success";
      else if (percent >= 33 && percent < 66) return "warning";
      else return "danger";
    },

    async showEndorsementListModal(PolicyUniqueId) {
      await this.$onpoint.loading(async() => {
        return await this.getListEndorsementPolicy(PolicyUniqueId).then(response => {
          this.endossoModal = true;
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--tbody {
    border: none !important;
  }

  ::v-deep .vs-table--thead {
    tr {
      background: transparent;

      th.text-center .vs-table-text {
        justify-content: center;
      }
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    // border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }

  .btn-action-apolice {
    cursor: pointer;
  }
}

.btn-action-apolice-options {
  width: 300px;

  ::v-deep .vs-dropdown--menu {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
}

.vs-table--tbody-table
  .tr-values.hoverFlat:not(.activeEdit):not(.is-expand):hover {
  opacity: unset;
}

.visualizar-endosso {
  width: 3px !important;
  height: 3px !important;
  margin-left: 7px;
  transform: scale(1.5);
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
