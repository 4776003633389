var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cadastro-segurado new-tabs" }, [
    _c(
      "div",
      { staticClass: "breadcrumb mb-6" },
      [
        (_vm.segurado || {}).Name
          ? _c("breadcrumb", {
              attrs: {
                title: "Segurado",
                actualPage: (_vm.segurado || {}).Name,
                previousPage: "segurados",
                previousPageTitle: "Lista de segurados",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "perfil-segurado" },
      [
        _c(
          "vs-tabs",
          { attrs: { color: "warning" } },
          [
            _c(
              "vs-tab",
              { attrs: { label: "Cadastro Completo" } },
              [
                _vm.segurado.Id
                  ? _c("dados-gerais", {
                      on: {
                        "load-segurado": function ($event) {
                          return _vm.carregarPagina()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "vs-tab",
              { attrs: { label: "Sócios / Administradores" } },
              [
                _c("TableSocios", {
                  attrs: { Socios: _vm.segurado.UniqueId, TypeText: undefined },
                }),
              ],
              1
            ),
            _c(
              "vs-tab",
              { attrs: { label: "Apólices" } },
              [
                _vm.segurado.Id
                  ? _c("apolices-segurado", {
                      attrs: { segurado: _vm.segurado },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "vs-tab",
              { attrs: { label: "Documentos" } },
              [_c("insured-documents")],
              1
            ),
            _vm.segurado.Id
              ? _c(
                  "vs-tab",
                  { attrs: { label: "Histórico de Alterações" } },
                  [
                    _c("historico-alteracao", {
                      attrs: { id: _vm.segurado.Id },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.exibirHistoricoConsultasSeguradoPorBureau && _vm.segurado.Id
              ? _c(
                  "vs-tab",
                  { attrs: { label: "Histórico de Consultas" } },
                  [
                    _c("HistoricoConsultasSegurado", {
                      attrs: { document: _vm.segurado.CpfCnpj },
                    }),
                  ],
                  1
                )
              : _c(
                  "vs-tab",
                  { attrs: { label: "Histórico de Consultas" } },
                  [
                    _c("historico-consultas", {
                      attrs: { document: _vm.segurado.CpfCnpj },
                    }),
                  ],
                  1
                ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }